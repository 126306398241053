import axios from "axios";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import ReactPlayer from "react-player";

import {
  Avatar,
  Box,
  IconButton,
  Typography,
  Card,
  CardMedia,
  Chip,
} from "@mui/material";

import {
  ChatBubbleOutline,
  PlayArrow,
  Star,
  StarBorder,
  Verified,
} from "@mui/icons-material";

import ActionDrawer from "@/components/ActionDrawer";
import ActiveContentDrawer from "@/components/ActiveContentDrawer";
import ImageCarousel from "@/components/ImageCarousel";
import PollContainer from "@/components/PollContainer";
import { API_BASE, currentUser } from "@/App";
import { fromNow, getContentArtwork } from "@/utils";

import { MusicContext } from "@/contexts/MusicContext";

export default function NoteCard({ content }) {
  const navigate = useNavigate();

  const { currentSong, handlePlay } = useContext(MusicContext);

  const [favorite, setFavorite] = useState(content.user_favorite);
  const [favoriteCount, setFavoriteCount] = useState(content.favorite_count);
  const [open, setOpen] = useState(false);

  const handleFavorite = async () => {
    setFavorite(!favorite);
    setFavoriteCount(favorite ? favoriteCount - 1 : favoriteCount + 1);
    const endpoint = API_BASE + "/event";
    const response = await axios.post(endpoint, {
      user_key: currentUser.key,
      content_key: content.key,
      type: favorite ? "unfavorite" : "favorite",
    });
  };

  return (
    <Box id={content.key} style={{ margin: "20px 0", display: "flex" }}>
      {/* <Divider/> */}
      <Avatar
        src={content.owner.meta.profile}
        sx={{ width: 40, height: 40, marginRight: 2, marginTop: 1 }}
        onClick={() => navigate("/" + content.owner.key)}
      />

      <Box id="post-content-container" style={{ flex: 1 }}>
        <Box
          id="post-header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            id="user-detail"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            <Typography
              variant="body"
              style={{ marginRight: "5px", fontWeight: "bold" }}
            >
              {/* {post.user.name} */}
              {content.owner.name}
            </Typography>

            <Verified
              color={content.owner.verified ? "secondary" : "disabled"}
              style={{ fontSize: "15px", marginRight: "5px" }}
            />

            <Typography
              variant="caption"
              style={{ marginRight: "5px", color: "darkgray" }}
            >
              {fromNow(content.created)}
            </Typography>
          </Box>

          <ActionDrawer content={content} />
        </Box>

        <Box id="post-content" style={{ marginTop: "-5px" }}>
          {content.meta.text != "" && (
            <Box
              style={{ wordWrap: "break-all", whiteSpace: "pre-wrap" }}
              sx={{ mb: 2 }}
            >
              <Typography variant="body" style={{ marginRight: "5px" }}>
                {content.meta.text}
              </Typography>
            </Box>
          )}

          {content.type == "carousel" && (
            <Box sx={{ borderRadius: "20px" }}>
              <ImageCarousel
                content={content}
                height={300}
                borderRadius={"20px"}
              />
            </Box>
          )}

          {content.type == "poll" && (
            <PollContainer content={content} setOpen={setOpen} />
          )}

          {content.type == "audio" && (
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "100%",
              }}
              onClick={() => handlePlay(content)}
            >
              <CardMedia
                component="img"
                src={getContentArtwork(content)}
                style={{ aspectRatio: "1/1", borderRadius: "10px" }}
              />
              <Typography
                variant="bold"
                style={{ position: "absolute", bottom: 20, left: 20 }}
              >
                {content.meta.title}
              </Typography>
              <Chip
                icon={<PlayArrow />}
                label="New Music"
                variant="outlined"
                color="primary"
                sx={{
                  position: "absolute",
                  bottom: 20,
                  right: 20,
                }}
              />
            </Box>
          )}

          {content.type == "image" && (
            <CardMedia
              component="img"
              src={content.meta.url}
              style={{ borderRadius: "10px" }}
            />
          )}

          {content.type == "video" && (
            <Card style={{ borderRadius: "20px" }}>
              <ReactPlayer
                url={content.meta.url}
                light={content.meta.cover}
                playing
                controls
                width="100%"
                height="400px"
              />
            </Card>
          )}

          {content.meta.tags && (
            <Typography variant="comment" style={{ color: "darkgray" }}>
              {/* <AlternateEmailIcon/> */}
              {content.meta.tags.join(", ")}
            </Typography>
          )}
        </Box>

        <Box
          id="post-actions"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: "10px",
            width: "100%",
            // gap: 4,
          }}
        >
          <Box id="handle-favorite" sx={{ ml: -1, mr: 1 }}>
            <IconButton onClick={handleFavorite}>
              {favorite ? <Star color="secondary" /> : <StarBorder />}
            </IconButton>
            <Typography variant="caption">
              {favoriteCount > 0 && favoriteCount}
            </Typography>
          </Box>
          <Box id="handle-chat">
            <IconButton
              onClick={() => setOpen(true)}
              style={{ marginLeft: "5px" }}
            >
              <ChatBubbleOutline />
            </IconButton>
            <Typography variant="caption" style={{ marginRight: "5px" }}>
              {content.comment_count > 0 && content.comment_count}
            </Typography>
          </Box>
        </Box>
      </Box>
      <ActiveContentDrawer content={content} open={open} setOpen={setOpen} />
    </Box>
  );
}
