import React, { useEffect, useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconButton,
  Link,
  Toolbar,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { artistKey, currentUser } from "../App";
import NotebookIcon from "../fragments/NotebookIcon";

export default function PrivateAppBar() {
  const [hideAlert, setHideAlert] = useState(
    localStorage.getItem("hideAlert") === "true"
  );
  const [open, setOpen] = useState(false);
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const isSpecialPage = [
      "/post",
      "/profile",
      "/broadcast",
      "/publish",
    ].includes(currentPath);

    // Don't show component on special pages
    if (isSpecialPage) {
      setShowComponent(false);
      return;
    }

    // Check if component should be shown based on user role and permissions
    const shouldShowComponent =
      currentUser &&
      currentUser.role === "artist" &&
      !currentUser.public &&
      currentUser.key === artistKey;

    setShowComponent(shouldShowComponent);

    // Only show dialog if component should be shown
    if (shouldShowComponent) {
      const alertHidden = localStorage.getItem("hideAlert") === "true";
      setOpen(!alertHidden);
    }
  }, []);

  const handleDoNotShowAgainChange = (event) => {
    const checked = event.target.checked;
    setHideAlert(checked);
    localStorage.setItem("hideAlert", checked.toString());
  };

  if (!showComponent) return null;

  return (
    <>
      <AppBar
        position="sticky"
        elevation={0}
        style={{ background: "rgba(36,36,36,.9)" }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 1,
          }}
        >
          <Button
            variant="text"
            style={{ color: "darkgray" }}
            onClick={() => setOpen(true)}
          >
            Help
          </Button>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <VisibilityOffIcon
              sx={{ mr: 1 }}
              style={{ color: "darkgray", fontSize: "1.2rem" }}
              onClick={() => setOpen(true)}
            />
            <Typography variant="body1" color="darkgray">
              Private Mode
            </Typography>
          </Box>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => (window.location.href = "/publish")}
            sx={{ borderRadius: 20 }}
          >
            Publish
          </Button>
        </Toolbar>
      </AppBar>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              my: 2,
              gap: 1,
            }}
          >
            <NotebookIcon />
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              Welcome to your Notebook!
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "flex-start", my: 3 }}>
            <IconButton
              onClick={() => (window.location.href = "/post")}
              style={{ background: "#9A5AEF" }}
              size="small"
              sx={{ mr: 2, my: 1 }}
            >
              <AddIcon />
            </IconButton>
            <Typography>
              You can now{" "}
              <Link href="/post" color="secondary">
                create a new post
              </Link>{" "}
              by clicking the (+) button.
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "flex-start", my: 3 }}>
            <Avatar
              onClick={() => (window.location.href = "/profile")}
              src={currentUser.meta.profile}
              sx={{ mr: 2, my: 1 }}
              style={{
                border: 1,
                borderColor: "#04FFFF",
                borderStyle: "solid",
                width: 34,
                height: 34,
              }}
            />
            <Typography>
              Visit{" "}
              <Link href="/profile" color="secondary">
                My Profile
              </Link>{" "}
              to customize your Notebook (pricing, cover photo, etc.)
            </Typography>
          </Box>

          <Typography sx={{ my: 2 }}>
            Feel free to{" "}
            <Link href="/juliamichaels" color="secondary">
              check out my Notebook
            </Link>{" "}
            for ideas and possibilities.
          </Typography>

          <Typography sx={{ my: 2 }}>
            All content remains private until you{" "}
            <Link href="/publish" color="secondary">
              publish
            </Link>{" "}
            your Notebook.
          </Typography>

          <Typography sx={{ my: 2 }}>
            Please let me know if you have any questions!
          </Typography>
          <Typography sx={{ my: 2 }}>- Julia</Typography>
          <Typography sx={{ my: 2 }}>Co-Founder & Head of Artists</Typography>
          <Typography sx={{ my: 2 }}>julia@notebook.fm</Typography>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between", pl: 3 }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={hideAlert}
                onChange={handleDoNotShowAgainChange}
                color="secondary"
              />
            }
            label="Hide"
          />
          <Button color="secondary" onClick={() => setOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
