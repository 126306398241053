import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Tabs,
  Tab,
  Typography,
  Button,
} from "@mui/material";

import { ArrowBackIos, ArrowForward, ArrowRight } from "@mui/icons-material";

import ArtistLogo from "@/fragments/ArtistLogo";

import ArtistAgreement from "./Agreement";

export default function ArtistPublish() {
  const [artistAgreement, setArtistAgreement] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  return (
    <Box>
      <PageAppBar />
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={tabValue}
          onChange={(e, value) => setTabValue(value)}
          variant="fullWidth"
        >
          <Tab label="Summary" />
          <Tab label="Agreement" />
        </Tabs>
      </Box>

      <Box sx={{ p: 2 }}>
        {tabValue === 0 && <AgreementSummary setTabValue={setTabValue} />}
        {tabValue === 1 && <ArtistAgreement />}
      </Box>
    </Box>
  );
}

function AgreementSummary({ setTabValue }) {
  return (
    <Box>
      <Typography variant="h6">Artist Agreement Summary</Typography>
      <Typography variant="body1" sx={{ my: 2 }} color="error">
        Work in progress // TODO: Add more details here
      </Typography>
      <Typography variant="body1" sx={{ my: 2 }}>
        Under the Notebook Artist Agreement, you grant Notebook FM a
        non-exclusive license to stream your sound recordings, videos, and other
        content (“Artist Content”) via their Platform and Service. While you
        retain ownership of your content, you agree that during the term of the
        agreement—which is initially two years and automatically renews
        annually—you will not offer a subscription service with exclusive
        content on any other platform, making Notebook’s Service your sole
        artist-specific subscription offering during this period. You are
        responsible for obtaining and paying for all necessary rights, licenses,
        permissions, and consents from third parties (e.g., songwriters,
        publishers, performers) to allow Notebook to use your content as
        outlined.
      </Typography>
      <Typography variant="body1" sx={{ my: 2 }}>
        In terms of payment, you will receive 55% of Net Revenues from your
        sound recordings streamed via the Service. Net Revenues are calculated
        as Gross Revenues (all subscription payments received) minus a 20%
        Operations Fee retained by Notebook. After deducting payments for
        publishing rights, payment processing fees, and hosting costs, you will
        receive the remaining Net Revenues for your participation in the
        Platform and provision of other content and user benefits. Royalties are
        paid quarterly via ACH deposit, and you have the right to audit
        Notebook’s records once per year to verify payments. Notebook is not
        obligated to pay additional amounts beyond those specified, and you are
        responsible for your own taxes related to payments received.
      </Typography>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => setTabValue(1)}
        sx={{ borderRadius: 20, my: 2 }}
        fullWidth
        endIcon={<ArrowForward />}
      >
        Review and Sign Agreement
      </Button>
    </Box>
  );
}

function PageAppBar() {
  const navigate = useNavigate();
  return (
    <AppBar
      position="sticky"
      elevation={0}
      style={{ background: "rgba(19,19,19,.9)" }}
    >
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIos />
        </IconButton>
        <ArtistLogo />
        <Box style={{ width: "40px" }} />
      </Toolbar>
    </AppBar>
  );
}
